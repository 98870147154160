import React from 'react';
import { Shield, Zap, RefreshCw } from 'lucide-react';

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <div className="flex items-center mb-4">
      <div className="text-blue-500 mr-3">{icon}</div>
      <h3 className="text-xl font-semibold">{title}</h3>
    </div>
    <p className="text-gray-600 text-sm">{description}</p>
  </div>
);

const FeatureHighlights = () => (
  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
    <FeatureCard
      icon={<Shield className="w-8 h-8" />}
      title="데이터 보안"
      description="민감 정보를 안전하게 보호합니다."
    />
    <FeatureCard
      icon={<Zap className="w-8 h-8" />}
      title="실시간 변환"
      description="Fhir7 변환을 위한 Json 형태로 빠르게 전환합니다."
    />
    <FeatureCard
      icon={<RefreshCw className="w-8 h-8" />}
      title="AI 기반 정확성 검증"
      description="Ai 빅데이터를 기반으로 검증하여 완벽한 정확도를 보장합니다."
    />
  </div>
);

export default FeatureHighlights;