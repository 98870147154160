export const sampleData = {
    subject_id: '32768',
    gender: 'male',
    dod: '',
    first_visit: '2024-10-02',
    name: '홍길동',
    assigner: '아주대학교병원',
    anchor_age: '',
    anchor_year: '',
    anchor_year_group: '',
    name_fam: '홍',
    name_given: '길동',
    symptom: '',
    birth_day: '1988-03-09',
    'phone(home)': '051-219-7083',
    'phone(work)': '031-219-7083',
    'phone(mobile)': '010-0209-7083'
  };