const styles = `
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

  body {
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5;
    color: #333;
    margin: 0;
    padding: 0;
  }

  .mui-container {
    width: 70%;
    margin: 0 auto;
    padding: 24px 0;
  }

  .mui-paper {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 24px;
    margin-bottom: 24px;
  }

  .mui-button {
    background-color: #1976d2;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .mui-button:hover {
    background-color: #1565c0;
  }

  .mui-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .header-container {
    position: relative;
    color: white;
    padding: 80px 0;
    text-align: center;
    overflow: hidden;
  }

  .header-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  .header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .header-content {
    position: relative;
    z-index: 2;
  }
`;

export default styles;