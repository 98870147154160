import React from 'react';
import { Clock, Calendar, Users } from 'lucide-react';

const StatisticsHeader = ({ stats = {} }) => {
  return (
    <header className="bg-gray-100 py-6 mt-5">
      <div className="container mx-auto px-4" style={{ maxWidth: '72%' }}>
        {/* <h1 className="text-3xl font-bold text-center mb-6">FHIR Conversion Service</h1> */}
      </div>
    </header>
  );
};

const StatCard = ({ title, value = 0, unit = "", icon }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <div className="flex items-center mb-2">
        {icon && <div className="mr-2 text-blue-500">{icon}</div>}
        <h2 className="text-xl font-semibold">{title}</h2>
      </div>
      <p className="text-3xl font-bold text-blue-600">
        {value.toLocaleString()}{unit && ` ${unit}`}
      </p>
    </div>
  );
};

export default StatisticsHeader;