// components/SidebarComponents.js
import React, { useState } from 'react';
import { ChevronRight, ChevronDown } from 'lucide-react';

export const SidebarSection = ({ title, children, isCollapsible = true, defaultCollapsed = false }) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
  
  return (
    <div className="py-2 border-b border-gray-700 last:border-b-0">
      <div className="flex items-center justify-between px-4 py-2">
        <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider">
          {title}
        </h3>
        {isCollapsible && (
          <button 
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="text-gray-400 hover:text-white transition-colors"
          >
            {isCollapsed ? <ChevronRight size={16} /> : <ChevronDown size={16} />}
          </button>
        )}
      </div>
      <div className={`
        overflow-hidden transition-all duration-300 ease-in-out
        ${isCollapsed ? 'max-h-0' : 'max-h-[1000px]'}
      `}>
        {children}
      </div>
    </div>
  );
};

export const MenuItem = ({ icon: Icon, label, description, isActive, onClick, shortcut }) => (
  <button
    onClick={onClick}
    className={`
      w-full text-left px-4 py-3 rounded-lg
      transition-all duration-200
      group relative
      ${isActive 
        ? 'bg-gray-700 text-white' 
        : 'text-gray-300 hover:bg-gray-700/50 hover:text-white'}
    `}
  >
    <div className="flex items-center">
      <Icon size={20} className="flex-shrink-0" />
      <div className="ml-3 flex-1">
        <span className="font-medium block">{label}</span>
        {description && (
          <span className="text-sm text-gray-400 group-hover:text-gray-300">
            {description}
          </span>
        )}
      </div>
      {shortcut && (
        <span className="text-xs text-gray-500 ml-2">{shortcut}</span>
      )}
    </div>
  </button>
);