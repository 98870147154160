const STATS_KEY = 'fhirConverterStats';

export const getStats = () => {
  const statsString = localStorage.getItem(STATS_KEY);
  if (statsString) {
    return JSON.parse(statsString);
  }
  return {
    totalJsonConversions: 0,
    totalValidationSuccesses: 0,
    averageProcessingTime: 0,
    todayConversions: 0,
    totalUsers: 0,
    totalProcessingTime: 0  // 새로운 필드 추가
  };
};


export const updateStats = (field, value) => {
  const stats = getStats();
  if (field === 'averageProcessingTime') {
    // 평균 처리 시간 업데이트 로직
    const totalConversions = stats.totalJsonConversions || 0;
    const totalTime = (stats.averageProcessingTime || 0) * totalConversions;
    stats.averageProcessingTime = (totalTime + value) / (totalConversions + 1);
  } else if (field === 'todayConversions' || field === 'totalJsonConversions' || field === 'totalValidationSuccesses') {
    // 카운트 증가
    stats[field] = (stats[field] || 0) + 1;
  } else {
    // 다른 필드의 경우 직접 값 설정
    stats[field] = (stats[field] || 0) + 1;
  }
  localStorage.setItem(STATS_KEY, JSON.stringify(stats));
};


export const resetDailyStats = () => {
  const stats = getStats();
  stats.todayConversions = 0;
  localStorage.setItem(STATS_KEY, JSON.stringify(stats));
};