import React from 'react';
import { FormInput, Zap, Check } from 'lucide-react';



const ConversionSteps = ({ step }) => {
  const steps = [
    { icon: <FormInput className="w-6 h-6" />, title: "데이터 입력" },
    { icon: <Zap className="w-6 h-6" />, title: "FHIR 변환 처리" },
    { icon: <Check className="w-6 h-6" />, title: "결과 확인 및 다운로드" },
  ];

  return (
    <div className="flex justify-between mb-12">
      {steps.map((s, index) => (
        <div key={index} className={`flex flex-col items-center ${step > index ? 'text-blue-600' : 'text-gray-400'}`}>
          <div className={`rounded-full p-3 mb-2 ${step > index ? 'bg-blue-100' : 'bg-gray-100'}`}>
            {s.icon}
          </div>
          <span className="text-sm font-medium">{s.title}</span>
        </div>
      ))}
    </div>
  );
};

export default ConversionSteps;