import React, { useState, useEffect } from 'react';
import { Download } from 'lucide-react';

const ConversionProcess = ({
  step,
  setStep,
  inputData,
  handleInputChange,
  isFormValid,
  handleConvert,
  fillSampleData,
  jsonOutput,
  setJsonOutput,
  handleValidate,
  validationResult,
  validationMessages,
  handleExportToExcel,  // exportToExcel 대신 handleExportToExcel로 변경
  resetForm,
  getLabelForKey,
  getPlaceholderForKey,
  isLoading,
  isValidating
}) => {
  const [editableJsonOutput, setEditableJsonOutput] = useState(jsonOutput);

  useEffect(() => {
    setEditableJsonOutput(jsonOutput);
  }, [jsonOutput]);

  const handleJsonOutputChange = (e) => {
    setEditableJsonOutput(e.target.value);
  };

  const handleConvertClick = () => {
    handleConvert();
  };

  return (
    <div className="mui-paper">
      {step === 1 && (
        <div>
          <h2 className="text-2xl font-semibold mb-4 text-center">변환 데이터 입력</h2>
          <p className="mb-4 text-gray-600 text-center">아래 필드에 변환할 데이터를 입력해 주세요.</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            {Object.entries(inputData).map(([key, value]) => (
              <div key={key}>
                <label htmlFor={key} className="block text-sm font-medium text-gray-700 mb-1">
                  {getLabelForKey(key)}
                </label>
                <input
                  type="text"
                  id={key}
                  name={key}
                  value={value}
                  onChange={handleInputChange}
                  placeholder={getPlaceholderForKey(key)}
                  className="w-full p-2 border rounded-md text-sm"
                />
              </div>
            ))}
          </div>
          <div className="mt-6 text-center">
            <button 
              onClick={handleConvertClick}
              className={`px-4 py-2 rounded-md transition-colors mr-4 ${
                isFormValid() 
                ? 'bg-blue-500 text-white hover:bg-blue-600' 
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
              disabled={!isFormValid()}
            >
              변환
            </button>
            <button 
              onClick={fillSampleData}
              className="px-4 py-2 rounded-md transition-colors bg-green-500 text-white hover:bg-green-600"
            >
              예시 데이터 채우기
            </button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="text-center">
          <h2 className="text-2xl font-semibold mb-6">FHIR 변환 진행 중</h2>
          <div className="w-full bg-gray-200 rounded-full h-2.5 mb-6">
            <div className="bg-blue-600 h-2.5 rounded-full" style={{width: '60%'}}></div>
          </div>
          <p className="text-lg">입력된 데이터를 JSon 형태로 변경중입니다. 잠시만 기다려주세요.</p>
        </div>
      )}
      {step === 3 && (
        <div className="text-center">
          <h2 className="text-2xl font-semibold mb-6">변환 완료!</h2>
          <p className="mb-6 text-lg">FHIR R4 형식으로 성공적으로 변환되었습니다.</p>
          <div className="flex flex-col md:flex-row gap-4">
            <div className="w-full md:w-1/2">
              <h3 className="text-lg font-semibold mb-2">변환 전 (입력 데이터)</h3>
              <pre className="w-full h-64 p-2 border rounded-md text-sm bg-gray-50 overflow-auto">
                {JSON.stringify(inputData, null, 2)}
              </pre>
            </div>
            <div className="w-full md:w-1/2">
              <h3 className="text-lg font-semibold mb-2">변환 후 (FHIR JSON)</h3>
              <textarea
                value={editableJsonOutput}
                onChange={handleJsonOutputChange}
                className="w-full h-64 p-2 border rounded-md text-sm font-mono"
              />
            </div>
          </div>
          <div className="mt-6">
            <button className="mui-button mr-4" onClick={resetForm}>
              처음으로
            </button>
            <button 
              className="mui-button" 
              onClick={() => handleValidate(editableJsonOutput)} 
              style={{backgroundColor: '#4caf50'}}
              disabled={isValidating}
            >
              {isValidating ? '검증 중...' : 'FHIR 유효성 검증'}
            </button>
          </div>
          {isValidating && (
            <div className="mt-4 text-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-500 mx-auto"></div>
              <p className="mt-2 text-gray-600">검증 중입니다. 잠시만 기다려주세요...</p>
            </div>
          )}
          {validationResult !== null && (
            <div className={`mt-6 p-4 rounded-md ${validationResult ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
              <h3 className="font-bold text-lg mb-2">{validationResult ? '검증 성공' : '검증 실패'}</h3>
              <p>{validationResult ? 'FHIR R4 형식이 유효합니다.' : '아래의 오류를 확인하고 데이터를 수정해주세요.'}</p>
              {!validationResult && validationMessages.length > 0 && (
                <ul className="list-disc pl-5 mt-2">
                  {validationMessages.map((message, index) => (
                    <li key={index}>{message}</li>
                  ))}
                </ul>
              )}
              {validationResult && (
                <button 
                onClick={handleExportToExcel}
                className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 
                          transition-colors shadow-md flex items-center justify-center mx-auto mb-6"
              >
                <Download size={20} className="mr-2" />
                검증 결과 엑셀로 저장
              </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ConversionProcess;