// components/NotificationSystem.js
import React from 'react';
import { Info, CheckCircle, AlertTriangle, XCircle, X } from 'lucide-react';

export const NotificationTypes = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
};

const NotificationIcons = {
  [NotificationTypes.INFO]: Info,
  [NotificationTypes.SUCCESS]: CheckCircle,
  [NotificationTypes.WARNING]: AlertTriangle,
  [NotificationTypes.ERROR]: XCircle
};

const NotificationColors = {
  [NotificationTypes.INFO]: 'blue',
  [NotificationTypes.SUCCESS]: 'green',
  [NotificationTypes.WARNING]: 'yellow',
  [NotificationTypes.ERROR]: 'red'
};

export const Notification = ({ type = NotificationTypes.INFO, message, onClose }) => {
  const color = NotificationColors[type];
  const Icon = NotificationIcons[type];

  return (
    <div className={`
      flex items-center p-4 bg-white dark:bg-gray-800 
      rounded-lg shadow-lg border-l-4 border-${color}-500
      transition-all duration-300 ease-in-out
    `}>
      <div className={`text-${color}-500 flex-shrink-0`}>
        <Icon size={20} />
      </div>
      <p className="ml-3 text-sm font-medium text-gray-800 dark:text-gray-200">{message}</p>
      {onClose && (
        <button 
          onClick={onClose}
          className="ml-auto flex-shrink-0 text-gray-400 hover:text-gray-500 
            focus:outline-none focus:ring-2 focus:ring-${color}-500 rounded"
        >
          <X size={16} />
        </button>
      )}
    </div>
  );
};

export const NotificationContainer = ({ notifications, onClose }) => (
  <div className="fixed top-4 right-4 z-50 space-y-2 max-w-md w-full">
    {notifications.map(notification => (
      <Notification
        key={notification.id}
        type={notification.type}
        message={notification.message}
        onClose={() => onClose(notification.id)}
      />
    ))}
  </div>
);