import React, { useState, useEffect } from 'react';
import { 
  Menu, LayoutDashboard, FileSpreadsheet, FileCheck, X,
  Sun, Moon, Bell, Settings, Home, ChevronRight, Clock, Star
} from 'lucide-react';
import FHIRConverter from './FHIRConverter';
import StandaloneConverter from './StandaloneConverter';
import ExcelUploader from './ExcelUploader';
import { ContentSkeleton } from './ContentSkeleton';
import { NotificationContainer, NotificationTypes } from './NotificationSystem';
import { SidebarSection, MenuItem } from './SidebarComponents';

const STORAGE_KEYS = {
  THEME: 'fhir-tool-theme',
  LAST_MENU: 'fhir-tool-last-menu',
  WORK_PROGRESS: 'fhir-tool-progress',
  RECENT_ITEMS: 'fhir-tool-recent',
  FAVORITES: 'fhir-tool-favorites'
};

const MainAppLayout = () => {
  // 기본 상태 관리
  const [selectedMenu, setSelectedMenu] = useState(() => {
    return localStorage.getItem(STORAGE_KEYS.LAST_MENU) || 'conversion';
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem(STORAGE_KEYS.THEME) === 'dark';
  });
  
  // 새로운 상태 관리
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [workProgress, setWorkProgress] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem(STORAGE_KEYS.WORK_PROGRESS) || '{}');
    } catch {
      return {};
    }
  });
  const [recentItems, setRecentItems] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem(STORAGE_KEYS.RECENT_ITEMS) || '[]');
    } catch {
      return [];
    }
  });
  const [favorites, setFavorites] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem(STORAGE_KEYS.FAVORITES) || '[]');
    } catch {
      return [];
    }
  });

  // 반응형 처리
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      if (mobile !== isMobile) {
        setIsSidebarOpen(!mobile);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  // 테마 처리
  useEffect(() => {
    document.documentElement.classList.toggle('dark', isDarkMode);
    localStorage.setItem(STORAGE_KEYS.THEME, isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const menuItems = [
    { 
      id: 'conversion',
      icon: LayoutDashboard,
      label: 'FHIR 검증 예시',
      description: 'Json형태로 변환 후 검증'
    },
    { 
      id: 'validation',
      icon: FileCheck,
      label: 'FHIR 검증기',
      description: 'FHIR 데이터 유효성 검사'
    },
    { 
      id: 'excel',
      icon: FileSpreadsheet,
      label: 'Excel 업로드',
      description: '업로드 후 FHIR7 검증'
    }
  ];

  // 작업 진행상태 저장
  const saveProgress = (key, data) => {
    const newProgress = { ...workProgress, [key]: data };
    setWorkProgress(newProgress);
    localStorage.setItem(STORAGE_KEYS.WORK_PROGRESS, JSON.stringify(newProgress));
  };

  // 최근 항목 관리
  const updateRecentItems = (menuId) => {
    const menuItem = menuItems.find(item => item.id === menuId);
    if (!menuItem) return;

    const newRecentItems = [
      { id: menuId, label: menuItem.label },
      ...recentItems.filter(item => item.id !== menuId)
    ].slice(0, 5);

    setRecentItems(newRecentItems);
    localStorage.setItem(STORAGE_KEYS.RECENT_ITEMS, JSON.stringify(newRecentItems));
  };

  // 즐겨찾기 관리
  const toggleFavorite = (menuId) => {
    const newFavorites = favorites.includes(menuId)
      ? favorites.filter(id => id !== menuId)
      : [...favorites, menuId];
    
    setFavorites(newFavorites);
    localStorage.setItem(STORAGE_KEYS.FAVORITES, JSON.stringify(newFavorites));
  };

  // 알림 시스템
  const addNotification = (message, type = NotificationTypes.INFO) => {
    const id = Date.now();
    const newNotification = { id, message, type };
    setNotifications(prev => [newNotification, ...prev]);
    setTimeout(() => {
      setNotifications(prev => prev.filter(n => n.id !== id));
    }, 5000);
  };

  const handleMenuClick = async (menu) => {
    if (menu === selectedMenu) return;
    
    setIsPageLoading(true);
    setSelectedMenu(menu);
    localStorage.setItem(STORAGE_KEYS.LAST_MENU, menu);
    updateRecentItems(menu);
    
    if (isMobile) {
      setIsSidebarOpen(false);
    }

    const menuItem = menuItems.find(item => item.id === menu);
    if (menuItem) {
      addNotification(`${menuItem.label}로 이동했습니다.`, NotificationTypes.SUCCESS);
    }
    
    await new Promise(resolve => setTimeout(resolve, 300));
    setIsPageLoading(false);
  };

  const renderContent = () => {
    if (isPageLoading) {
      return <ContentSkeleton />;
    }

    const savedProgress = workProgress[selectedMenu];
    
    switch (selectedMenu) {
      case 'conversion':
        return (
          <FHIRConverter 
            initialData={savedProgress} 
            onProgressChange={(data) => saveProgress('conversion', data)} 
          />
        );
      case 'validation':
        return (
          <StandaloneConverter 
            initialData={savedProgress}
            onProgressChange={(data) => saveProgress('validation', data)}
          />
        );
      case 'excel':
        return (
          <ExcelUploader 
            initialData={savedProgress}
            onProgressChange={(data) => saveProgress('excel', data)}
          />
        );
      default:
        return <FHIRConverter />;
    }
  };

  const getCurrentMenuItem = () => menuItems.find(item => item.id === selectedMenu);

  return (
    <div className={`relative flex h-screen overflow-hidden transition-colors duration-200
      ${isDarkMode ? 'dark bg-gray-900' : 'bg-gray-100'}`}>
      {/* 알림 시스템 */}
      <NotificationContainer 
        notifications={notifications}
        onClose={(id) => setNotifications(prev => prev.filter(n => n.id !== id))}
      />

      {/* Backdrop */}
      {isMobile && isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-30 transition-opacity"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Sidebar */}
      <aside
        className={`
          fixed md:relative
          ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'}
          ${isMobile ? 'w-[280px]' : 'w-64'}
          h-full
          ${isDarkMode ? 'bg-gray-900' : 'bg-gray-800'}
          z-40
          transition-all duration-300 ease-in-out
          flex flex-col
          flex-shrink-0
          shadow-lg
        `}
      >
        {/* Sidebar Header */}
        <div className={`h-16 flex items-center justify-between px-4 border-b 
          ${isDarkMode ? 'border-gray-700 bg-gray-800' : 'border-gray-700 bg-gray-900'}`}>
          <span className="text-white text-lg font-semibold">FHIR Tool</span>
          {isMobile && (
            <button 
              onClick={() => setIsSidebarOpen(false)}
              className="p-1 rounded-full text-gray-400 hover:text-white hover:bg-gray-700 
                transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label="Close menu"
            >
              <X size={20} />
            </button>
          )}
        </div>

        {/* Sidebar Content */}
        <div className="flex-1 overflow-y-auto">
          <SidebarSection title="메인 메뉴">
            {menuItems.map((item) => (
              <MenuItem
                key={item.id}
                icon={item.icon}
                label={item.label}
                description={item.description}
                isActive={selectedMenu === item.id}
                onClick={() => handleMenuClick(item.id)}
              />
            ))}
          </SidebarSection>

          {favorites.length > 0 && (
            <SidebarSection title="즐겨찾기" icon={Star}>
              {favorites.map(menuId => {
                const item = menuItems.find(i => i.id === menuId);
                return item && (
                  <MenuItem
                    key={item.id}
                    icon={item.icon}
                    label={item.label}
                    isActive={selectedMenu === item.id}
                    onClick={() => handleMenuClick(item.id)}
                  />
                );
              })}
            </SidebarSection>
          )}

          <SidebarSection title="최근 작업" icon={Clock}>
            {recentItems.map(item => {
              const menuItem = menuItems.find(i => i.id === item.id);
              return menuItem && (
                <MenuItem
                  key={item.id}
                  icon={menuItem.icon}
                  label={item.label}
                  isActive={selectedMenu === item.id}
                  onClick={() => handleMenuClick(item.id)}
                />
              );
            })}
          </SidebarSection>
        </div>

        {/* Sidebar Footer */}
        <div className={`p-4 border-t border-gray-700 flex justify-between items-center
          ${isDarkMode ? 'bg-gray-800' : 'bg-gray-900'}`}>
          <button
            onClick={() => setIsDarkMode(prev => !prev)}
            className="p-2 rounded-lg text-gray-400 hover:text-white hover:bg-gray-700
              transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
            aria-label={`Switch to ${isDarkMode ? 'light' : 'dark'} mode`}
          >
            {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
          </button>
          <button
            onClick={() => setShowNotifications(prev => !prev)}
            className="p-2 rounded-lg text-gray-400 hover:text-white hover:bg-gray-700
              transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 relative"
            aria-label="Show notifications"
          >
            <Bell size={20} />
            {notifications.length > 0 && (
              <span className="absolute -top-1 -right-1 w-2 h-2 bg-red-500 rounded-full" />
            )}
          </button>
          <button
            className="p-2 rounded-lg text-gray-400 hover:text-white hover:bg-gray-700
              transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
            aria-label="Settings"
          >
            <Settings size={20} />
          </button>
        </div>
      </aside>

      {/* Main Content Wrapper */}
      <div className={`
        flex flex-col flex-1 
        ${isSidebarOpen ? 'md:ml-0' : 'ml-0'}
        transition-all duration-300
        min-h-0
      `}>
        {/* Header */}
        <header className={`h-16 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} 
          shadow-sm flex-shrink-0 relative flex items-center justify-between px-4 z-20`}>
          <div className="flex items-center">
            {isMobile && (
              <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="mr-4 text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white
                  focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg p-1"
                aria-label="Toggle menu"
              >
                <Menu size={24} />
              </button>
            )}
            
            {/* Breadcrumbs */}
            <div className="flex items-center space-x-2">
              <button
                onClick={() => handleMenuClick('conversion')}
                className={`flex items-center space-x-1 px-2 py-1 rounded-lg
                  ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}
                  hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors`}
              >
                <Home size={16} />
                <span>Home</span>
              </button>
              <ChevronRight size={16} className="text-gray-400" />
              <span className={`${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`}>
                {getCurrentMenuItem()?.label}
              </span>
            </div>
          </div>

{/* 툴바 영역 */}
<div className="flex items-center space-x-2">
            <button
              onClick={() => toggleFavorite(selectedMenu)}
              className={`p-2 rounded-lg transition-colors
                ${favorites.includes(selectedMenu)
                  ? 'text-yellow-400 hover:text-yellow-500'
                  : 'text-gray-400 hover:text-gray-500'}
              `}
              aria-label={favorites.includes(selectedMenu) ? '즐겨찾기 해제' : '즐겨찾기 추가'}
            >
              <Star size={20} />
            </button>
          </div>
        </header>

        {/* Main Content */}
        <main className="flex-1 overflow-y-auto relative">
          <div className="container mx-auto p-6 min-h-0">
            {renderContent()}
          </div>
        </main>

        {/* Footer */}
        <footer className={`${isDarkMode ? 'bg-gray-800 text-gray-400' : 'bg-white text-gray-600'} 
          shadow-sm flex-shrink-0 py-4`}>
          <div className="container mx-auto px-4 text-center">
            <p>&copy; 2024 FHIR Conversion Service. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default MainAppLayout;