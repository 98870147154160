import React from 'react';
import { CheckCircle } from 'lucide-react';

const Alert = ({ message, onClose }) => {
  return (
    <div className="fixed top-4 right-4 bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded shadow-md flex items-center justify-between" role="alert">
      <div className="flex items-center">
        <CheckCircle className="mr-2" />
        <span>{message}</span>
      </div>
      <button onClick={onClose} className="text-green-700 hover:text-green-900 ml-4">
        <span className="text-2xl">&times;</span>
      </button>
    </div>
  );
};

export default Alert;