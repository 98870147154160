import React, { useState } from 'react';
import { handleValidate } from './fhirUtils';
import StatisticsHeader from './StatisticsHeader';

const StandaloneConverter = () => {
  const [jsonInput, setJsonInput] = useState('');
  const [validationResult, setValidationResult] = useState(null);
  const [validationMessages, setValidationMessages] = useState([]);
  const [isValidating, setIsValidating] = useState(false);

  const [stats, setStats] = useState({
    totalJsonConversions: 0,
    totalValidationSuccesses: 0,
    averageProcessingTime: 0,
    todayConversions: 0,
    totalUsers: 0
  });

  const handleInputChange = (e) => {
    setJsonInput(e.target.value);
  };

  const validateJson = () => {
    setIsValidating(true);
    handleValidate(jsonInput, (result, messages) => {
      setValidationResult(result);
      setValidationMessages(messages);
      setIsValidating(false);
    }, () => {});
  };

  return (
    <div>
      <StatisticsHeader stats={stats} />
      <div className="mb-4">
        <label htmlFor="jsonInput" className="block text-sm font-medium text-gray-700 mb-2">
          FHIR JSON 입력
        </label>
        <textarea
          id="jsonInput"
          value={jsonInput}
          onChange={handleInputChange}
          className="w-full h-64 p-2 border rounded-md text-sm font-mono"
          placeholder="여기에 FHIR JSON을 입력하세요..."
        />
      </div>
      <button
        onClick={validateJson}
        disabled={isValidating}
        className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors ${isValidating ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {isValidating ? '검증 중...' : 'FHIR 유효성 검증'}
      </button>
      {isValidating && (
        <div className="mt-4 text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-2 text-gray-600">검증 중입니다. 잠시만 기다려주세요...</p>
        </div>
      )}
      {validationResult !== null && (
        <div className={`mt-6 p-4 rounded-md ${validationResult ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
          <h3 className="font-bold text-lg mb-2">{validationResult ? '검증 성공' : '검증 실패'}</h3>
          <p>{validationResult ? 'FHIR R4 형식이 유효합니다.' : '아래의 오류를 확인하고 데이터를 수정해주세요.'}</p>
          {!validationResult && validationMessages.length > 0 && (
            <ul className="list-disc pl-5 mt-2">
              {validationMessages.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default StandaloneConverter;