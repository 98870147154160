import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Download } from 'lucide-react';
import ConversionSteps from './ConversionSteps';
import ConversionProcess from './ConversionProcess';
import FeatureHighlights from './FeatureHighlights';
import StatisticsHeader from './StatisticsHeader';
import StandaloneConverter from './StandaloneConverter';
import { handleConvert, handleValidate } from './fhirUtils';  // 다시 추가
import { getStats, updateStats, resetDailyStats } from './statsUtils';
import { sampleData } from './sampleData';
import styles from '../styles/styles';

const initialInputData = {
  subject_id: '', gender: '', dod: '', first_visit: '', name: '', assigner: '',
  anchor_age: '', anchor_year: '', anchor_year_group: '', name_fam: '', 
  name_given: '', symptom: '', birth_day: '', 'phone(home)': '', 'phone(work)': '', 'phone(mobile)': ''
};

const getLabelForKey = (key) => {
  const labels = {
    subject_id: '환자 ID',
    gender: '성별',
    dod: '사망일',
    first_visit: '첫 방문일',
    name: '이름',
    name_fam: '성',
    name_given: '이름',
    birth_day: '생년월일',
    'phone(home)': '집 전화번호',
    'phone(work)': '직장 전화번호',
    'phone(mobile)': '휴대폰 번호',
    assigner: '의료기관',
    anchor_age: '기준 나이',
    anchor_year: '기준 연도',
    anchor_year_group: '기준 연도 그룹',
    symptom: '증상'
  };
  return labels[key] || key;
};

const getPlaceholderForKey = (key) => {
  const placeholders = {
    subject_id: '예: 12345',
    gender: '예: 남성 또는 여성',
    dod: '예: 2023-01-01',
    first_visit: '예: 2023-01-01',
    name: '예: 홍길동',
    name_fam: '예: 홍',
    name_given: '예: 길동',
    birth_day: '예: 1990-01-01',
    'phone(home)': '예: 02-1234-5678',
    'phone(work)': '예: 02-9876-5432',
    'phone(mobile)': '예: 010-1234-5678',
    assigner: '예: 서울대학교병원',
    anchor_age: '예: 30',
    anchor_year: '예: 2023',
    anchor_year_group: '예: 2020-2025',
    symptom: '예: 두통, 발열'
  };
  return placeholders[key] || `Enter ${key}`;
};

const FHIRConverter = () => {
  const [step, setStep] = useState(1);
  const [inputData, setInputData] = useState(initialInputData);
  const [jsonOutput, setJsonOutput] = useState('');
  const [validationResult, setValidationResult] = useState(null);
  const [validationMessages, setValidationMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [stats, setStats] = useState({
    totalJsonConversions: 0,
    totalValidationSuccesses: 0,
    averageProcessingTime: 0,
    todayConversions: 0,
    totalUsers: 0
  });

  useEffect(() => {
    const loadStats = () => {
      const currentStats = getStats();
      setStats(currentStats);
    };

    loadStats();
    
    const interval = setInterval(loadStats, 5000);

    const midnight = new Date();
    midnight.setHours(24, 0, 0, 0);
    const msUntilMidnight = midnight.getTime() - new Date().getTime();

    const timeoutId = setTimeout(() => {
      resetDailyStats();
      setInterval(resetDailyStats, 24 * 60 * 60 * 1000);
    }, msUntilMidnight);

    return () => {
      clearInterval(interval);
      clearTimeout(timeoutId);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData(prevData => ({ ...prevData, [name]: value }));
  };

  const fillSampleData = () => {
    setInputData(sampleData);
  };

  const resetForm = () => {
    setInputData(initialInputData);
    setJsonOutput('');
    setValidationResult(null);
    setValidationMessages([]);
    setStep(1);
  };

  const isFormValid = () => Object.values(inputData).some(value => value.trim() !== '');

  const convertData = () => {
    setIsLoading(true);
    setStep(2);
    updateStats('totalJsonConversions');
    updateStats('todayConversions');
    const startTime = Date.now();
    handleConvert(inputData, (jsonOutput) => {
      setJsonOutput(jsonOutput);
      setStep(3);
      const endTime = Date.now();
      const processingTime = (endTime - startTime) / 1000;
      updateStats('averageProcessingTime', processingTime);
      setStats(getStats());
      setIsLoading(false);
    }, setIsLoading);
  };
  
  const validateData = () => {
    setIsValidating(true);
    handleValidate(jsonOutput, (result, messages) => {
      setValidationResult(result);
      setValidationMessages(messages);
      if (result) {
        updateStats('totalValidationSuccesses');
        setStats(getStats());
      }
      setIsValidating(false);
    }, setIsLoading);
  };

  const handleExportToExcel = () => {
    if (!validationResult || !jsonOutput) return;

    const exportData = [{
      ...inputData,
      val_rslt: validationResult ? 'SUCCESS' : 'FAILURE',
      val_time: new Date().toISOString(),
      fhir_json: jsonOutput ? JSON.stringify(jsonOutput)
        .replace(/\\n/g, '')
        .replace(/\\r/g, '')
        .replace(/\\"/g, '"')
        .replace(/\\/g, '')
        .replace(/\s+/g, ' ')
        : ''
    }];

    const originalKeys = Object.keys(exportData[0]);
    const fhirJsonIndex = originalKeys.indexOf('fhir_json');
    if (fhirJsonIndex > -1) {
      originalKeys.splice(fhirJsonIndex, 1);
      originalKeys.push('fhir_json');
    }

    const reorderedData = exportData.map(row => {
      const newRow = {};
      originalKeys.forEach(key => {
        if (row[key] !== undefined) {
          newRow[key] = row[key];
        }
      });
      return newRow;
    });

    const ws = XLSX.utils.json_to_sheet(reorderedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "FHIR Validation Result");

    const colWidths = {};
    originalKeys.forEach(key => {
      colWidths[key] = { wch: key === 'fhir_json' ? 150 : 20 };
    });
    ws['!cols'] = Object.values(colWidths);

    const fileName = `FHIR_Validation_Single_${new Date().toISOString().slice(0,19).replace(/[:-]/g,'')}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  useEffect(() => {
    updateStats('totalUsers');
    setStats(getStats());
  }, []);

  return (
    <div>
      <style>{styles}</style>
      <StatisticsHeader stats={stats} />
      <main className="flex-1 overflow-auto">
        <div className="container mx-auto px-4 py-6">
          <ConversionSteps step={step} />
          <ConversionProcess 
              step={step}
              setStep={setStep}
              inputData={inputData}
              handleInputChange={handleInputChange}
              isFormValid={isFormValid}
              handleConvert={convertData}
              fillSampleData={fillSampleData}
              jsonOutput={jsonOutput}
              setJsonOutput={setJsonOutput}
              handleValidate={validateData}
              validationResult={validationResult}
              validationMessages={validationMessages}
              exportToExcel={handleExportToExcel}  // 이 부분을 아래처럼 변경
              handleExportToExcel={handleExportToExcel}  // 변경된 prop 이름
              resetForm={resetForm}
              getLabelForKey={getLabelForKey}
              getPlaceholderForKey={getPlaceholderForKey}
              isLoading={isLoading}
              isValidating={isValidating}
            />
          <FeatureHighlights />
        </div>
      </main>
    </div>
  );
};

export default FHIRConverter;
