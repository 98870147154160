import * as XLSX from 'xlsx';

export const handleConvert = (inputData, callback, setIsLoading) => {
  setIsLoading(true);

  const baseTemplate = {
    "resourceType": "Patient",
    "id": inputData.subject_id || "",
    "identifier": [{
      "use": "usual",
      "type": {
        "coding": [{
          "system": "http://terminology.hl7.org/CodeSystem/v2-0203",
          "code": "MR"
        }]
      },
      "system": "urn:oid:1.2.36.146.595.217.0.1",
      "value": inputData.subject_id || "",
      "period": {
        "start": inputData.first_visit || ""
      },
      "assigner": {
        "display": inputData.assigner || ""
      }
    }],
    "active": true,
    "name": [{
      "use": "official",
      "family": inputData.name_fam || "",
      "given": [inputData.name_given || ""]
    }],
    "telecom": [
      {
        "system": "phone",
        "value": inputData['phone(home)'] || "",
        "use": "home"
      },
      {
        "system": "phone",
        "value": inputData['phone(mobile)'] || "",
        "use": "mobile"
      },
      {
        "system": "phone",
        "value": inputData['phone(work)'] || "",
        "use": "work"
      }
    ],
    "gender": inputData.gender || "",
    "birthDate": inputData.birth_day || "",
    "_birthDate": {
      "extension": [{
        "url": "http://hl7.org/fhir/StructureDefinition/patient-birthTime",
        "valueDateTime": inputData.birth_day ? `${inputData.birth_day}T00:00:00-00:00` : ""
      }]
    },
    "deceasedBoolean": !!inputData.dod,
    "deceasedDateTime": inputData.dod || undefined
  };

  // 추가 필드 처리
  const additionalFields = ['anchor_age', 'anchor_year', 'anchor_year_group', 'symptom'];
  const extensions = additionalFields
    .filter(field => inputData[field])
    .map(field => ({
      url: `http://example.com/fhir/StructureDefinition/${field}`,
      valueString: inputData[field]
    }));

  if (extensions.length > 0) {
    baseTemplate.extension = extensions;
  }

  setTimeout(() => {
    const jsonOutput = JSON.stringify(baseTemplate, null, 2);
    callback(jsonOutput);
    setIsLoading(false);
  }, 2000);
};

export const handleValidate = (jsonOutput, callback, setIsLoading) => {
  setIsLoading(true);

  setTimeout(() => {
    try {
      const fhirData = JSON.parse(jsonOutput);
      const isValid = fhirData.resourceType === 'Patient' && fhirData.id;
      const messages = isValid 
        ? ['FHIR Patient 구조가 유효합니다.']
        : ['기본 FHIR Patient 구조가 유효하지 않습니다.'];
      callback(isValid, messages);
    } catch (error) {
      console.error("Validation error:", error);
      callback(false, [error.message || '알 수 없는 오류가 발생했습니다.']);
    } finally {
      setIsLoading(false);
    }
  }, 1000);
};

export const exportToExcel = (jsonOutput, inputData, validationResult) => {
  try {
    const jsonData = JSON.parse(jsonOutput);
    
    const flattenObject = (obj, prefix = '') => {
      return Object.keys(obj).reduce((acc, k) => {
        const pre = prefix.length ? prefix + '.' : '';
        if (typeof obj[k] === 'object' && obj[k] !== null && !Array.isArray(obj[k])) {
          Object.assign(acc, flattenObject(obj[k], pre + k));
        } else {
          acc[pre + k] = obj[k];
        }
        return acc;
      }, {});
    };

    const flatData = flattenObject(jsonData);
    
    const metaData = {
      '검증결과': validationResult ? '검증 성공' : '검증 실패',
      '변환 일시': new Date().toLocaleString()
    };
    const metaSheet = XLSX.utils.json_to_sheet([metaData]);
    
    const inputSheet = XLSX.utils.json_to_sheet([inputData]);
    const fhirSheet = XLSX.utils.json_to_sheet([flatData]);
    
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, metaSheet, "Meta Info");
    XLSX.utils.book_append_sheet(wb, inputSheet, "Original Input");
    XLSX.utils.book_append_sheet(wb, fhirSheet, "FHIR Data");
    
    XLSX.writeFile(wb, "fhir_data_with_input_and_validation.xlsx");
  } catch (error) {
    console.error("Excel export error:", error);
    alert("엑셀 파일 생성 중 오류가 발생했습니다.");
  }
};