import React, { useState, useRef, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Upload, ChevronLeft, ChevronRight, ChevronDown, ChevronUp, CheckCircle, XCircle, Download } from 'lucide-react';
import { handleConvert, handleValidate } from './fhirUtils';
import Alert from './Alert';

const ExcelUploader = () => {
  const [excelData, setExcelData] = useState([]);
  const [uploadCount, setUploadCount] = useState(0);
  const [validationResults, setValidationResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showAlert, setShowAlert] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [showUploadedData, setShowUploadedData] = useState(true);
  const fileInputRef = useRef(null);

  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showAlert]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary', cellDates: true, dateNF: 'yyyy-mm-dd hh:mm:ss' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { raw: false, dateNF: 'yyyy-mm-dd hh:mm:ss' });
      
      const convertedData = data.map(row => {
        const newRow = {...row};
        if (newRow.first_visit) {
          const date = new Date(newRow.first_visit);
          if (!isNaN(date.getTime())) {
            newRow.first_visit = date.toISOString();
          }
        }
        return newRow;
      });

      setExcelData(convertedData);
      setUploadCount(convertedData.length);
      setShowAlert(true);
      setShowUploadedData(true);
      setValidationResults(null);
    };
    reader.readAsBinaryString(file);
  };

  const handleDataChange = (index, field, value) => {
    const updatedData = [...excelData];
    updatedData[index][field] = value;
    setExcelData(updatedData);
  };

  const handleExportToExcel = () => {
    if (!validationResults || !excelData.length) return;
  
    // FHIR JSON 변환을 위한 Promise 배열 생성
    const conversionPromises = excelData.map((row) => {
      return new Promise((resolve) => {
        handleConvert(row, (jsonOutput) => {
          const validationInfo = validationResults.errors.find(error => error.row === row.subject_id);
          
          // JSON 문자열 정리
          const cleanJson = JSON.stringify(jsonOutput)
            .replace(/\\n/g, '')  // 줄바꿈 제거
            .replace(/\\r/g, '')  // 캐리지 리턴 제거
            .replace(/\\"/g, '"') // 이스케이프된 따옴표 처리
            .replace(/\\/g, '')   // 남은 백슬래시 제거
            .replace(/\s+/g, ' '); // 연속된 공백을 하나로
  
          // 내보낼 데이터 구성
          const exportRow = {
            ...row,
            val_rslt: validationInfo ? 'FAILURE' : 'SUCCESS',
            val_time: new Date().toISOString(),
            fhir_json: cleanJson
          };
  
          resolve(exportRow);
        }, () => {});
      });
    });
  
    // 모든 변환이 완료된 후 엑셀 생성
    Promise.all(conversionPromises).then((exportData) => {
      // 컬럼 순서 재정렬
      const originalKeys = Object.keys(exportData[0]);
      const fhirJsonIndex = originalKeys.indexOf('fhir_json');
      if (fhirJsonIndex > -1) {
        originalKeys.splice(fhirJsonIndex, 1);
        originalKeys.push('fhir_json');
      }
  
      // 새로운 순서로 데이터 재구성
      const reorderedData = exportData.map(row => {
        const newRow = {};
        originalKeys.forEach(key => {
          if (row[key] !== undefined) {
            newRow[key] = row[key];
          }
        });
        return newRow;
      });
  
      // 엑셀 워크시트 생성
      const ws = XLSX.utils.json_to_sheet(reorderedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Validation Results");
  
      // 컬럼 너비 설정
      const colWidths = {};
      originalKeys.forEach(key => {
        colWidths[key] = { wch: key === 'fhir_json' ? 150 : 20 };
      });
      ws['!cols'] = Object.values(colWidths);
  
      // 파일 저장
      const fileName = `FHIR_Validation_Results_${new Date().toISOString().slice(0,19).replace(/[:-]/g,'')}.xlsx`;
      XLSX.writeFile(wb, fileName);
    });
  };

  const validateData = () => {
    setIsValidating(true);
    setShowUploadedData(false);
    let successCount = 0;
    let failCount = 0;
    let allErrors = [];

    const validationPromises = excelData.map(row => {
      return new Promise((resolve) => {
        handleConvert(row, (jsonOutput) => {
          handleValidate(jsonOutput, (isValid, errors) => {
            if (isValid) {
              successCount++;
            } else {
              failCount++;
              allErrors.push({ row: row.subject_id, errors });
            }
            resolve();
          }, () => {});
        }, () => {});
      });
    });

    Promise.all(validationPromises).then(() => {
      setValidationResults({
        total: excelData.length,
        success: successCount,
        fail: failCount,
        errors: allErrors
      });
      setIsValidating(false);
    });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = excelData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="space-y-6">
      <div className="mb-8">
        <div className="flex items-center justify-center w-full">
          <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <Upload size={48} className="text-gray-500 mb-4" />
              <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">클릭하여 업로드</span> 또는 드래그 앤 드롭</p>
              <p className="text-xs text-gray-500">XLSX, XLS (MAX. 10MB)</p>
            </div>
            <input
              ref={fileInputRef}
              id="dropzone-file"
              type="file"
              className="hidden"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
            />
          </label>
        </div>
      </div>
      
      {showAlert && (
        <div className="fixed top-4 right-4 z-50">
          <Alert 
            message={`총 ${uploadCount}건의 데이터가 성공적으로 업로드되었습니다.`} 
            onClose={() => setShowAlert(false)} 
          />
        </div>
      )}
      
      {excelData.length > 0 && (
        <div className="space-y-6">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold">업로드된 데이터:</h3>
            <button 
              onClick={() => setShowUploadedData(!showUploadedData)}
              className="text-blue-600 hover:text-blue-800"
            >
              {showUploadedData ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
          </div>
          
          {showUploadedData && (
            <div className="space-y-4">
              <div className="overflow-x-auto bg-white rounded-lg shadow">
                <div className="inline-block min-w-full align-middle">
                  <table className="min-w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        {Object.keys(excelData[0]).map((key) => (
                          <th key={key} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                            {key}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {currentItems.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {Object.entries(row).map(([key, value], cellIndex) => (
                            <td key={`${rowIndex}-${cellIndex}`} className="px-6 py-4 whitespace-nowrap">
                              <input
                                type="text"
                                value={value}
                                onChange={(e) => handleDataChange(rowIndex + indexOfFirstItem, key, e.target.value)}
                                className="w-full border-gray-300 rounded-md shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              
              <div className="flex justify-between items-center">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors disabled:opacity-50"
                >
                  <ChevronLeft size={20} />
                </button>
                <span>Page {currentPage} of {Math.ceil(excelData.length / itemsPerPage)}</span>
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === Math.ceil(excelData.length / itemsPerPage)}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors disabled:opacity-50"
                >
                  <ChevronRight size={20} />
                </button>
              </div>
            </div>
          )}
          
          <div className="flex justify-center space-x-4">
            {validationResults && (
              <button 
                onClick={() => setShowUploadedData(!showUploadedData)}
                className="px-6 py-3 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-colors shadow-md"
              >
                {showUploadedData ? '검증 데이터 접기' : '검증 데이터 펼치기'}
              </button>
            )}
            <button
              onClick={validateData}
              disabled={isValidating}
              className={`px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors shadow-md ${isValidating ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isValidating ? '검증 중...' : validationResults ? 'FHIR 재검증' : 'FHIR 유효성 검증'}
            </button>
          </div>
          
          {isValidating && (
            <div className="text-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto"></div>
              <p className="mt-2 text-gray-600">검증 중입니다. 잠시만 기다려주세요...</p>
            </div>
          )}
          
          {validationResults && (
            <div className="p-6 bg-gray-50 rounded-lg shadow-lg text-center">
              <h4 className="text-2xl font-bold mb-4">검증 결과</h4>
              <p className="text-xl mb-4">총 {validationResults.total}건의 데이터 중</p>
              <div className="flex justify-center items-center space-x-8 mb-6">
                <div className="flex items-center">
                  <CheckCircle size={32} className="text-green-500 mr-2" />
                  <span className="text-xl font-semibold">{validationResults.success}건 성공</span>
                </div>
                <div className="flex items-center">
                  <XCircle size={32} className="text-red-500 mr-2" />
                  <span className="text-xl font-semibold">{validationResults.fail}건 실패</span>
                </div>
              </div>
              <p className="text-2xl font-bold text-blue-600 mb-6">
                성공률: {((validationResults.success / validationResults.total) * 100).toFixed(2)}%
              </p>

              {/* 엑셀 다운로드 버튼 */}
              <button
                onClick={handleExportToExcel}
                className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 
                         transition-colors shadow-md flex items-center justify-center mx-auto mb-6"
              >
                <Download size={20} className="mr-2" />
                검증 결과 엑셀로 저장
              </button>

              {validationResults.errors.length > 0 && (
                <div className="text-left">
                  <h5 className="text-xl font-semibold mb-4">오류 상세:</h5>
                  <div className="bg-white p-4 rounded-lg shadow max-h-96 overflow-y-auto">
                    <ul className="list-disc pl-5">
                      {validationResults.errors.map((error, index) => (
                        <li key={index} className="mb-4">
                          <span className="font-semibold text-lg">Row ID {error.row}:</span>
                          <ul className="list-disc pl-5 mt-2">
                            {error.errors.map((err, errIndex) => (
                              <li key={errIndex} className="text-red-600">{err}</li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ExcelUploader;